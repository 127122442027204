.burgerMenu {
    padding-top: 23px;
    padding-left: 10px;
    color: #ffffff !important;
}

.burgerMenuSmall {
    padding-top: 12px;
    padding-left: 10px;
    color: #ffffff !important;
}

.navbar {
    background-color: #2E3B55;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.acc-loc-bar {
   background-color: #bd632f;
   width:100% !important;
   color: #ffffff;
    position: fixed !important;
    z-index: 998;
    max-height: 20px !important;
    border-width: 0px !important;
    vertical-align: middle;
    font-size: 14px !important;
    font-weight: normal !important;
    padding: 0px !important;
    margin:0px !important;
}

.acc-loc-item {
    padding: 0px 4px 0px 4px !important;
    color: #ffffff !important;
    max-height: 20px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.nav-menu {
    background-color: #273e47 !important;
    width: 100%;
    margin-top:20px !important;
    max-height: 70px;
    position: fixed !important;
    transition: 550ms;
    z-index: 999;
}

    .nav-menu.active {
        left: 0;
        transition: 250ms;
    }


.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    height: 50px;
}

.nav-sel {
    color: #f5f5f5;
}

.nav-text a, .nav-text-last a {
    color: #f5f5f5;
    display: flex;
}

.nav-switch-entity {
    color: #f5f5f5 !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    height: 100px;
}

.nav-menu-items {
    width: 100%;
}

.nav-text-last {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    height: 50px;
    position: absolute;
    bottom: 60px;
}

.navbar-toggle {
    background-color: #273e47;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: solid 1px red;
}

.sidebar-menu {
    background-color: #273e47 !important;
    height: 110vh !important;
    padding-top: 100px !important;
    margin-top:90px !important;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 150px !important;
    position: fixed !important;
}

.sidebar-menu-thin {
    background-color: #273e47 !important;
    height: 110vh !important;
    padding-top: 100px !important;
    padding-left: 5px;
    padding-right: 0px !important;
    max-width: 45px !important;
    position: fixed !important;
}

.sidebar-menu-item-thin {
    width: 30px !important;
    min-width: 30px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px !important;
    margin-left: 2px !important;
    text-align: center;
}

.sidebar-menu-item-sel {
  background-color: #bd632f !important;
}

.sidebar-menu-item-bottom {
    position:absolute !important;
    bottom:0;
    width: 145px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px !important;
    text-align: center;
}

.sidebar-menu-item-bottom-thin {
    position:absolute !important;
    bottom:0;
    width: 30px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px !important;
    text-align: center;
}

.main-content-segment {
    padding:0px;
    padding-top: 8em !important;
    min-height: 100vh;
    display: flex;
    margin: 0px !important;
    flex-direction: column;
}